import { logout } from "../../actions/default.actions";
import Dropdown from "../Dropdown/Dropdown";
import "./MenuHeader.scss";
import { useDisclosure } from "../../hooks/utils.hooks";
import Modal from "../Modal/Modal";
import FormComponent from "../FormComponent/FormComponent";
import config from "../../app.config.json";
import { getIconByKey } from "../../constants/icons.retriever";

const endpointsMapping = config?.app?.endpointsMapping;
const formsMapping = config?.app?.formsMapping;

const MenuHeader = () => {
  const { open, onOpen } = useDisclosure();

  const userInfos = JSON.parse(String(sessionStorage.getItem("userInfos")));
  const options = [
    { label: "Profil", value: "profil" },
    { label: "Mot de passe", value: "password" },
    { label: "Déconnexion", value: "logout" },
  ];
  const _onSelect = (value) => {
    if (value?.value === "logout") {
      logout(false);
    }
    if (value?.value === "password") {
      onOpen(true);
    }
  };
  const mappingKey = "password-update";
  return (
    <div className="MenuHeaderWrapper">
      <Modal
        modalVisibiliy={open}
        setModalVisibility={onOpen}
        title={"Mettre à jour le mot de passe"}>
        <br />
        <div className="passwordUpdateModalAvatar">
          {getIconByKey("avatar")}
        </div>
        <FormComponent
          key={`${mappingKey}-formContent`}
          typeKey={"password-formContent"}
          formContent={formsMapping?.[`${mappingKey}`]}
          post={`${endpointsMapping?.[`${mappingKey}`]["put"]}/${
            userInfos?.id
          }/password`}
          type="post"
          formtype="center"
        />
        <br />
      </Modal>
      {/* <svg style={{ marginRight: 25 }} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
        />
      </svg> */}
      {getIconByKey("notifications")}
      <Dropdown
        style={{
          marginRight: "20px",
        }}
        dropdownStyle={{
          right: "10px",
        }}
        options={options}
        onSelect={_onSelect}>
        {getIconByKey("avatar")}
        <span style={{ color: "black", marginLeft: 10 }}>
          {userInfos?.firstName ?? "Admin"}
        </span>
      </Dropdown>
    </div>
  );
};

export default MenuHeader;
