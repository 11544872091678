import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profil from "./pages/Profil/Profil";
import Generic from "./pages/Generic/Generic";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import Auth from "./pages/Auth/Auth";
import "./App.scss";
import config from "./app.config.json";
const dynamicMenu = config?.app?.dynamicMenu;

function App() {
  const virtualMenu = [];
  for (let index = 0; index < dynamicMenu?.length; index++) {
    for (
      let subIndex = 0;
      subIndex < dynamicMenu?.[index]?.content.length;
      subIndex++
    ) {
      virtualMenu.push(dynamicMenu?.[index]?.content?.[subIndex]);
    }
  }
  const isMobile = window?.visualViewport?.width < 1280;

  if (isMobile) {
    return (
      <div>
        <h3 style={{ textAlign: "center" }}>
          Veuillez vous connecter avec un ordinateur.
        </h3>
      </div>
    );
  }

  //showGeneralLoading();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<Auth />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="home" element={<Home />} />
            <Route path="profil" element={<Profil />} />
            {virtualMenu && virtualMenu?.length > 0 && (
              <>
                {virtualMenu?.map((menuItem) => (
                  <Route
                    key={`virtual-menu-${menuItem?.key}`}
                    path={`${menuItem?.key}`}
                    element={
                      <Generic mappingKey={`${menuItem?.mappingKey}`} />
                    }>
                    {menuItem?.nested && menuItem?.nested?.length > 0 && (
                      <>
                        {menuItem?.nested?.map((nestedItem) => (
                          <Route
                            path={`${nestedItem?.key}`}
                            element={
                              <Generic
                                mappingKey={`${nestedItem?.mappingKey}`}
                              />
                            }
                          />
                        ))}
                      </>
                    )}
                  </Route>
                ))}
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
