import "./FormComponent.scss";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  getAction,
  postActions,
  putActions,
} from "../../actions/default.actions";
import { showToast } from "../../constants/utils";
import config from "../../app.config.json";
import Loader from "../Loader/Loader";
import Select from "react-select";

const endpointsMapping = config?.app?.endpointsMapping;

const FormComponent = ({
  typeKey,
  formContent,
  tabSetter,
  bread,
  setBread,
  setFormData,
  type,
  post,
  put,
  requestState,
  record,
  formtype = "list",
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(new Map());
  const [showSubmit, setshowSubmit] = useState(true);
  const onSubmit = async (data) => {
    selectedValues?.forEach((value, key, map) => {
      data[`${key}`] = value?.value;
    });
    try {
      if (type === "post") {
        const response = await postActions(post, data);
        if (response?.data?.message) {
          showToast(response?.data?.message);
        }
        if (formtype !== "list") {
          return;
        }
        requestState({
          state: "create",
          data: response.data.data,
        });
      } else {
        if (record._id) {
          data.id = record._id;
          const response = await putActions(put, data);
          showToast(response.data.message);
          requestState({
            state: "update",
            data: response.data.data,
          });
        } else {
          showToast("Mise à jour non autorisée");
        }
      }
      setBread([bread.shift()]);
    } catch (error) {
      console.log(error);
    }
  };

  const selectedValue = (item) => {
    if (item?.fetch) {
      return record?.[`${item?.label}`]?.[`${item?.fetch?.value}`];
    }
    return record?.[`${item?.label}`];
  };

  const dateValue = (item) => {
    const date = new Date(
      new Date(record?.[`${item?.label}`]).toLocaleString("en-US", {
        timeZone: "Africa/Abidjan",
      })
    );
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const handleValueChange = (label, value, isValueFetched = false) => {
    const newValue = selectedValues;
    if (isValueFetched) {
      newValue.set(`${label}`, {
        label: label,
        value: value?.value,
      });
    } else {
      newValue.set(`${label}`, {
        label: value?.label,
        value: value?.value,
      });
    }
    setSelectedValues((prev) => newValue);
  };

  useEffect(() => {
    if (bread && bread[bread.length - 1]?.label === "Visualiser") {
      setshowSubmit(false);
    }
    setTimeout(() => {
      if (formContent && !isLoading) {
        for (let index = 0; index < formContent.length; index++) {
          const element = formContent[index];
          switch (element.type) {
            case "date":
              document.querySelector(`input[name=${element?.label}]`).value =
                dateValue(element);
              setValue(element?.label, dateValue(element));

              break;
            case "area":
              document.querySelector(`textarea[name=${element?.label}]`).value =
                record?.[`${element?.label}`];
              setValue(element?.label, record?.[`${element?.label}`]);

              break;

            default:
              // document.querySelector(`input[name=${element?.label}]`).value =
              //   record?.[`${element?.label}`];
              setValue(element?.label, record?.[`${element?.label}`]);

              break;
          }
        }
      }
    }, 100);
  }, [formContent, isLoading]);

  useEffect(() => {
    const dataToFetch = [];
    for (let index = 0; index < formContent.length; index++) {
      const input = formContent[index];
      if (input?.type === "select" && input?.fetch) {
        dataToFetch.push(
          // eslint-disable-next-line no-loop-func
          new Promise(async (resolve, reject) => {
            const response = await getAction(
              endpointsMapping?.[input?.fetch?.endPointKey]["get"],
              {
                limit:
                  config?.app?.tablesMapping?.[`${typeKey}`]?.params?.limit ??
                  10,
              }
            );
            const retrievedData =
              input?.fetch?.reachingKey !== null &&
              input?.fetch?.reachingKey !== undefined
                ? response?.data?.[`${input?.fetch?.reachingKey}`]
                : response?.data;

            const returnedData = [];
            for (let index = 0; index < retrievedData?.length; index++) {
              returnedData.push({
                label: retrievedData?.[index]?.[`${input?.fetch?.label}`],
                value: retrievedData?.[index]?.[`${input?.fetch?.value}`],
              });
            }
            // set default values
            const newValue = selectedValues;
            newValue.set(`${input?.label}`, {
              label: record?.[`${input?.label}`]?.[`${input?.fetch?.label}`],
              value: record?.[`${input?.label}`]?.[`${input?.fetch?.value}`],
            });
            setSelectedValues((prev) => newValue);
            resolve({
              key: input?.label,
              data: returnedData,
            });
          })
        );
      }
    }
    Promise.all(dataToFetch).then((values) => {
      setFetchedData(values);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      for (let index = 0; index < formContent.length; index++) {
        const element = formContent[index];
        switch (element.type) {
          case "select":
            if (!element?.fetch) {
              const newValue = selectedValues;
              newValue.set(`${element?.label}`, {
                label: element?.values?.find(
                  (item) => item?.value === record?.[`${element?.label}`]
                )?.label,
                value: record?.[`${element?.label}`],
              });
              setSelectedValues((prev) => newValue);
            }
            break;

          default:
            break;
        }
      }
    });
  }, []);

  return (
    <div className="FormComponentWrapper">
      {bread && (
        <div className="goBack">
          <div
            className="container"
            onClick={() => {
              tabSetter("list");
              setBread([bread.shift()]);
            }}>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M18,11H10L13.5,7.5L12.08,6.08L6.16,12L12.08,17.92L13.5,16.5L10,13H18V11Z"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="formContent">
        <form
          {...(formtype === "center" && {
            style: {
              justifyContent: "center",
            },
          })}
          onSubmit={handleSubmit(onSubmit)}>
          {formContent?.length > 0 &&
            formContent?.map((item) =>
              item?.type === "area" ? (
                <>
                  <div className="textArea">
                    <h4>{item?.placeholder}</h4>
                    <div className="input">
                      <textarea
                        //value={record?.[`${item?.label}`]}
                        placeholder={item?.placeholder}
                        {...register(item?.label, {
                          required: item?.required ?? false,
                        })}
                      />
                    </div>
                    <div>
                      {errors[item?.label]?.type === "required" && (
                        <p role="alert">{item?.placeholder} est obligatoire</p>
                      )}
                    </div>
                  </div>
                </>
              ) : item?.type === "select" ? (
                <>
                  <div className="formInput customInput">
                    <h4>{item?.placeholder}</h4>
                    <div className="input">
                      {item?.fetch ? (
                        <>
                          {!!fetchedData?.length ? (
                            <Select
                              className="custom-select"
                              options={
                                fetchedData.find(
                                  (fetched) => fetched?.key === item?.label
                                )?.data
                              }
                              defaultValue={selectedValues.get(
                                `${item?.label}`
                              )}
                              onChange={(value) => {
                                handleValueChange(item?.label, value, true);
                              }}
                              placeholder="Selectionner un element"
                            />
                          ) : (
                            <Loader />
                          )}
                        </>
                      ) : (
                        <>
                          <Select
                            className="custom-select"
                            options={item?.values}
                            defaultValue={item?.values?.find(
                              (subItem) =>
                                subItem?.value === record?.[`${item?.label}`]
                            )}
                            onChange={(value) => {
                              handleValueChange(item?.label, value, false);
                            }}
                            placeholder="Selectionner un element"
                          />
                        </>
                      )}
                    </div>
                    <div>
                      {errors[item?.label]?.type === "required" && (
                        <p role="alert">{item?.placeholder} est obligatoire</p>
                      )}
                    </div>
                  </div>
                </>
              ) : item?.type === "text" ? (
                <>
                  <div className="formInput customInput tt">
                    <h4>{item?.placeholder}</h4>
                    <div className="input">
                      <input
                        type={item?.type}
                        placeholder={item?.placeholder}
                        {...register(item?.label, {
                          required: item?.required ?? false,
                        })}
                      />
                    </div>
                    <div>
                      {errors[item?.label]?.type === "required" && (
                        <p role="alert">{item?.placeholder} est obligatoire</p>
                      )}
                    </div>
                  </div>
                </>
              ) : item?.type === "number" ? (
                <>
                  <div className="formInput customInput tt">
                    <div className="input">
                      <input
                        //value={record?.[`${item?.label}`]}
                        type={item?.type}
                        placeholder={item?.placeholder}
                        {...register(item?.label, {
                          required: item?.required ?? false,
                        })}
                      />
                    </div>
                    <div>
                      {errors[item?.label]?.type === "required" && (
                        <p role="alert">{item?.placeholder} est obligatoire</p>
                      )}
                    </div>
                  </div>
                </>
              ) : item?.type.indexOf(["time", "date", "datetime-local"]) !==
                1 ? (
                <>
                  <div className="formInput customInput tt">
                    <h4>{item?.placeholder}</h4>
                    <div className="input">
                      <input
                        //value={dateValue(item)}
                        type={item?.type}
                        placeholder={item?.placeholder}
                        {...register(item?.label, {
                          required: item?.required ?? false,
                        })}
                      />
                    </div>
                    <div>
                      {errors[item?.label]?.type === "required" && (
                        <p role="alert">{item?.placeholder} est obligatoire</p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )
            )}

          {/* register your input into the hook by invoking the "register" function
					<input defaultValue="test" {...register("example")} />
					
					include validation with required or other standard HTML validation rules
					<input {...register("exampleRequired", { required: item?.required ?? false })} />
					errors will return when field validation fails 
					{errors.exampleRequired && <span>This field is required</span>} */}
          {showSubmit && (
            <div className="submitDiv">
              <input className="app-btn" type="submit" value={"Valider"} />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
