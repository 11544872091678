import { useState } from "react";

export const useDisclosure = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  return {
    open: modalVisibility,
    onOpen: setModalVisibility,
  };
};
