
import './NotFound.scss'
import notFound from '../../assets/img/notFound.svg'


const NotFound = () => 
{
	return(
		<div className="NotFoundWrapper">
			<img src={notFound} /> <br/>
			Pas de données pour le moment
		</div>
	)
}

export default NotFound