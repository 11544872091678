import "./Dashboard.scss";
import MenuHeader from "../../components/MenuHeader/MenuHeader";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { useEffect } from "react";
import config from "../../app.config.json";
import { getIconByKey } from "../../constants/icons.retriever";
const dynamicMenu = config?.app?.dynamicMenu;
const appName = config?.app?.name;

const Dashboard = () => {
  const navigate = useNavigate();
  const setActiveTab = (id) => {
    const allOptions = document.querySelectorAll(".menuItem");
    for (let index = 0; index < allOptions.length; index++) {
      const element = allOptions[index];
      element.classList.remove("activeMenuItem");
    }
    if (document.getElementById(id)) {
      document.getElementById(id).classList.add("activeMenuItem");
    }
  };
  useEffect(() => {
    const id = "menuItem-" + window.location.href.split("/")[4];
    if (document.getElementById(id)) {
      setActiveTab(id);
    }
    if (!sessionStorage.getItem("userInfos")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="WelcomeWrapper">
      <div className="toolset">
        <div className="logo">
          <div className="name">
            <img
              src={logo}
              onClick={() => {
                setActiveTab("menuItem-home");
                navigate("/dashboard/home", { replace: false });
              }}
              alt="Logo"
            />
            &nbsp;
            <div>{appName}</div>
          </div>
          <div>{getIconByKey("apps")}</div>
        </div>

        {dynamicMenu && dynamicMenu?.length > 0 && (
          <>
            {dynamicMenu.map((menuItem, index) => (
              <div key={`groupeMenu-${index}`}>
                <div className={`groupeName-title groupeMenu-${index}`}>
                  {menuItem?.groupeName}{" "}
                  {menuItem?.icon ? getIconByKey(menuItem?.icon) : ""}
                </div>
                {menuItem?.content?.map((subItemMenu, subIndex) => (
                  <div
                    key={`subItemMenu-${subIndex}`}
                    className={`menuItem ${
                      index === 0 && subIndex === 0 ? "activeMenuItem" : ""
                    }`}
                    id={`menuItem-${subItemMenu?.key}`}
                    onClick={() => {
                      setActiveTab(`menuItem-${subItemMenu?.key}`);
                      if (subItemMenu?.url && subItemMenu?.url?.length > 0) {
                        navigate(subItemMenu?.url, { replace: false });
                        navigate(0);
                      } else {
                        navigate(`/dashboard/${subItemMenu?.key}`, {
                          replace: false,
                        });
                        navigate(0);
                      }
                    }}
                    style={{ marginTop: "5%" }}>
                    <div className="icon">
                      {subItemMenu?.icon?.length > 0 ? (
                        <>{getIconByKey(subItemMenu?.icon)}</>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="title">{subItemMenu?.label}</div>
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
      <div className="content">
        <div className="toolbar">
          <div className="menu">
            <MenuHeader />
          </div>
        </div>
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
