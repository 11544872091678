import "./Dropdown.scss";

const Dropdown = ({ children, options, onSelect, style, dropdownStyle }) => {
  return (
    <div className="dropdown" style={style}>
      <button className="dropbtn">{children}</button>
      <div className="dropdown-content" style={dropdownStyle}>
        {options?.map((item, index) => (
          <a key={item?.label + index} href="#" onClick={() => onSelect(item)}>
            {item?.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
