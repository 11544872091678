
import './StockChart.scss'
import CanvasJSReact from '../../assets/js/canvasjs.react';
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const StockChart = () => 
{
	const options = {
		theme: "light2",
		title: {
			text: "Performance"
		},
		axisY: {
			title: "Price in USD",
			prefix: "$"
		},
		data: [{
			type: "line",
			xValueFormatString: "MMM YYYY",
			yValueFormatString: "$#,##0.00",
			dataPoints: [
				{
					x: new Date("09-09-2022"),
					y: 4000
				},
				{
					x: new Date("09-10-2022"),
					y: 7000
				},
				{
					x: new Date("09-11-2022"),
					y: 8000
				}
			]
		}]
	}
	return(
		<div className="StockChartWrapper">
			<CanvasJSChart options = {options} 
			/>
		</div>
	)
}

export default StockChart