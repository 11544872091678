import PieChart from "../../components/PieChart/PieChart";
import BarChart from "../../components/BarChart/BarChart";
import StockChart from "../../components/StockChart/StockChart";
import StatRecap from "../../components/StatRecap/StatRecap";
import TableComponent from "../../components/TableComponent/TableComponent";
import "./Home.scss";

const Home = () => {
  const setting = [{ label: "Nom" }, { label: "Prénom" }, { label: "Email" }];
  const tableIcon1 = (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"
      />
    </svg>
  );
  const dataSource = [
    { data: ["L'administrateur", "Pispros", "admin@admin.com"] },
    { data: ["L'administrateur", "Pispros", "admin@admin.com"] },
    { data: ["L'administrateur", "Pispros", "admin@admin.com"] },
  ];
  return (
    <div className="HomeWrapper">
      <StatRecap />
      <br />
      <div className="bigTab">
        <h2>Dernières opérations</h2>
        <TableComponent
          settings={setting}
          dataSource={dataSource}
          icon={tableIcon1}
        />
      </div>
      <br />
      <br />
      <div className="content">
        <div className="someGraphs">
          <div className="barchart">
            <BarChart />
          </div>
          <div className="barchart">
            <StockChart />
          </div>
        </div>
      </div>
      <div className="pies">
        <div className="content">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default Home;
