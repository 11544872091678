export const AUTH_REQUEST         = "AUTH_REQUEST";
export const AUTH_REQUEST_SUCCESS = "AUTH_REQUEST_SUCCESS";
export const AUTH_REQUEST_ERROR   = "AUTH_REQUEST_ERROR";

export const USER_INFOS_REQUEST   = "USER_INFOS_REQUEST";
export const USER_INFOS_SUCCESS   = "USER_INFOS_SUCCESS";
export const USER_INFOS_ERROR     = "USER_INFOS_ERROR";

export const LOGOUT = "LOGOUT";

export const RELOAD_LIST = "RELOAD_LIST";
export const STOP_RELOAD_LIST = "STOP_RELOAD_LIST";