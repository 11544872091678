import "./Generic.scss";

import NotFound from "../../components/NotFound/NotFound";
import { useEffect, useState } from "react";
import BreadCumb from "../../components/BreadCumb/BreadCumb";
import FormComponent from "../../components/FormComponent/FormComponent";
import TableComponent from "../../components/TableComponent/TableComponent";
import {
  getAction,
  transformDataForTableComponent,
} from "../../actions/default.actions";
import config from "../../app.config.json";
import { BASE_URL } from "../../constants/environnement.types";
const menuConfig = config?.app?.dynamicMenu;
const endpointsMapping = config?.app?.endpointsMapping;
const formsMapping = config?.app?.formsMapping;
const tablesMapping = config?.app?.tablesMapping;

const Generic = ({ mappingKey, title = "test" }) => {
  const [currentOption, setcurrentOption] = useState("list");
  const [record, recordSetter] = useState(null);
  const [retreivedData, setRetrievedData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [formContent, setFormContent] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [metaCount, setMetaCount] = useState(10);
  const [hasNext, setHasNext] = useState(undefined);
  const [hasPrevious, setHasPrevious] = useState(undefined);
  const [next, setNext] = useState(undefined);
  const [previous, setPrevious] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [addedOrUpdatedData, setAddedOrUpdatedData] = useState({
    state: "",
    data: {},
  });
  const groupe = menuConfig.find((item) =>
    item?.content?.find((subItem) => subItem?.mappingKey === mappingKey)
  );

  const [bread, setBread] = useState([
    {
      label: groupe?.content?.find((item) => item?.key === mappingKey)
        ?.breadMenu,
      key: mappingKey,
    },
  ]);
  const tableIcon = (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"
      />
    </svg>
  );
  const settings = tablesMapping?.[`${mappingKey}`]?.table;
  const tableSettings = tablesMapping?.[`${mappingKey}`]?.params;

  const _retrieveData = async () => {
    return await getAction(
      `${BASE_URL}/${config?.app?.endpointsMapping[mappingKey]["get"]}&skip=${
        currentPage * 10
      }&number=10`
    );
  };

  const performFetchAndFormatData = async () => {
    _retrieveData().then((data) => {
      if (data?.data?.length > 0) {
        setHasNext(data?.hasNext);
        setHasPrevious(data?.hasPrevious);
        setNext(data?.next);
        setPrevious(data?.previous);
        setMetaCount(data?.count);
        setRetrievedData(data?.data);
        const transData = transformDataForTableComponent(settings, data?.data);
        setDataSource(transData);
      } else {
        setDataSource([]);
      }
    });
  };

  useEffect(() => {
    performFetchAndFormatData();
  }, [currentPage]);

  useEffect(() => {
    setFormContent(formsMapping?.[`${mappingKey}`]);
    const groupe = menuConfig.find((item) =>
      item?.content?.find((subItem) => subItem?.mappingKey === mappingKey)
    );
    setBread((prev) => [
      {
        label: groupe?.content?.find((item) => item?.key === mappingKey)
          ?.breadMenu,
        key: mappingKey,
      },
    ]);
  }, [mappingKey]);

  useEffect(() => {
    if (record) {
      for (let index = 0; index < formContent?.length; index++) {
        const element = formContent[index];
        element.defaultValue = record?.[element?.["label"]];
      }
    }
  }, [record]);
  useEffect(() => {
    if (currentOption === "list") {
      for (let index = 0; index < formContent?.length; index++) {
        const element = formContent[index];
        delete element.defaultValue;
      }
    }
  }, [currentOption]);
  useEffect(() => {
    if (addedOrUpdatedData.state !== "") {
      if (addedOrUpdatedData.state === "create") {
        setRetrievedData([...retreivedData, addedOrUpdatedData.data]);
        setDataSource([
          ...dataSource,
          ...transformDataForTableComponent(settings, [
            addedOrUpdatedData.data,
          ]),
        ]);
        setcurrentOption("list");
      }
      if (addedOrUpdatedData.state === "update") {
        const foundIndex = retreivedData.findIndex(
          (data) => data._id === addedOrUpdatedData.data._id
        );
        const temp = [...retreivedData];
        temp[foundIndex] = addedOrUpdatedData.data;
        const transData = transformDataForTableComponent(settings, temp);
        setDataSource(transData);
        setcurrentOption("list");
      }
      if (addedOrUpdatedData.state === "delete") {
        const foundIndex = retreivedData.findIndex(
          (data) => data._id === addedOrUpdatedData.data._id
        );
        const temp = [...retreivedData];
        retreivedData.splice(foundIndex, 1);
        temp.splice(foundIndex, 1);
        setRetrievedData(temp);
        const transData = transformDataForTableComponent(settings, temp);
        setDataSource(transData);
        setTimeout(() => {
          setcurrentOption((prev) => "list");
        }, 100);
      }
    }
  }, [addedOrUpdatedData]);
  useEffect(() => {
    document.querySelector(".outlet").style.overflowY = "auto";
  }, []);

  return (
    <>
      <div className="GenericWrapper custom-wrapper">
        <BreadCumb situation={bread} />
        <div className="spacer"></div>
        {currentOption === "list" && dataSource.length > 0 ? (
          <TableComponent
            key={mappingKey}
            bread={bread}
            requestState={setAddedOrUpdatedData}
            setBread={setBread}
            tabSetter={setcurrentOption}
            recordSetter={recordSetter}
            icon={tableIcon}
            settings={settings}
            dataSource={dataSource}
            canEdit={tableSettings?.canEdit}
            canDelete={tableSettings?.canDelete}
            canAdd={tableSettings?.canAdd}
            canCopy={tableSettings?.canCopy}
            canManageFiles={tableSettings?.canManageFiles}
            withMaxWidth={tableSettings?.withMaxWidth}
            currentKey={mappingKey}
            metaCount={metaCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
          />
        ) : currentOption === "add" ? (
          <FormComponent
            key={`${mappingKey}-formContent`}
            typeKey={mappingKey}
            formContent={formContent}
            setFormData={setFormData}
            bread={bread}
            setBread={setBread}
            tabSetter={setcurrentOption}
            post={endpointsMapping?.[`${mappingKey}`]["post"]}
            type="post"
            record={record}
            requestState={setAddedOrUpdatedData}
          />
        ) : dataSource.length > 0 ? (
          <FormComponent
            typeKey={mappingKey}
            formContent={formContent}
            formData={formData}
            setFormData={setFormData}
            bread={bread}
            setBread={setBread}
            tabSetter={setcurrentOption}
            record={record}
            put={endpointsMapping?.[`${mappingKey}`]["put"]}
            type="put"
            requestState={setAddedOrUpdatedData}
          />
        ) : (
          <>
            <TableComponent
              key={mappingKey}
              bread={bread}
              requestState={setAddedOrUpdatedData}
              setBread={setBread}
              tabSetter={setcurrentOption}
              recordSetter={recordSetter}
              icon={tableIcon}
              settings={settings}
              dataSource={dataSource}
              canEdit={tableSettings?.canEdit}
              canDelete={tableSettings?.canDelete}
              canAdd={tableSettings?.canAdd}
              canCopy={tableSettings?.canCopy}
              canManageFiles={tableSettings?.canManageFiles}
              withMaxWidth={tableSettings?.withMaxWidth}
              currentKey={mappingKey}
              metaCount={metaCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
            />
            <NotFound />
          </>
        )}
      </div>
    </>
  );
};

export default Generic;
