import "./Modal.scss";

const Modal = ({
  children,
  title,
  modalVisibiliy,
  setModalVisibility,
  clickOustside = false,
}) => {
  return (
    <>
      {modalVisibiliy && (
        <div className="ModalWrapper">
          <div
            onClick={() => clickOustside && setModalVisibility(false)}
            className="modal-overlay"></div>
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h3>{title}</h3>
              </div>
              <div>
                <span
                  onClick={() => setModalVisibility(false)}
                  className="close">
                  &times;
                </span>
              </div>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
