
import './Profil.scss'


const Profil = () => 
{
	return(
		<div className="ProfilWrapper">
			<div></div>
			Profil works
		</div>
	)
}

export default Profil