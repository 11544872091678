export const showToast = (message) => {
  const toastWrapper = document.createElement("div");
  toastWrapper.setAttribute("class", "ToastWrapper");
  const messageContent = document.createElement("div");
  messageContent.setAttribute("class", "messageContent");
  messageContent.innerHTML = message;
  toastWrapper.appendChild(messageContent);
  document.querySelector("body").appendChild(toastWrapper);
  setTimeout(() => {
    toastWrapper.remove();
  }, 3000);
};

export const showLoading = () => {
  const loadingWrapper = document.createElement("div");
  loadingWrapper.setAttribute("class", "LoadingWrapper");
  loadingWrapper.setAttribute("id", "LoadingWrapper");
  const spinner = document.createElement("div");
  spinner.setAttribute("class", "lds-spinner");
  spinner.innerHTML = `<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>`;
  loadingWrapper.appendChild(spinner);
  document.querySelector("body").appendChild(loadingWrapper);
};

export const showGeneralLoading = () => {
  const GeneralLoadingWrapper = document.createElement("div");
  GeneralLoadingWrapper.setAttribute("class", "GeneralLoadingWrapper");
  GeneralLoadingWrapper.setAttribute("id", "GeneralLoadingWrapper");
  const spinner = document.createElement("div");
  spinner.setAttribute("class", "lds-spinner");
  spinner.innerHTML = `<div class="lds-hourglass"></div>`;
  GeneralLoadingWrapper.appendChild(spinner);
  document.querySelector("body").appendChild(GeneralLoadingWrapper);
  setTimeout(() => {
    GeneralLoadingWrapper.remove();
  }, 1000);
};

export const hideLoading = () => {
  document.getElementById("LoadingWrapper").remove();
};
