import "./UploadComponent.scss";
import config from "../../app.config.json";
import { downloadCsvFile } from "../../constants/files.utils";
import Papa from "papaparse";
import { useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import { getIconByKey } from "../../constants/icons.retriever";
import {
  getAction,
  postActions,
  transformDataForTableComponent,
} from "../../actions/default.actions";
import { showToast } from "../../constants/utils";
import Dropdown from "../Dropdown/Dropdown";
const tablesMapping = config?.app?.tablesMapping;
const endpointsMapping = config?.app?.endpointsMapping;

const UploadComponent = ({ mappingKey, dataSource, metaCount }) => {
  const [parsedData, setparsedData] = useState([]);
  const [previsualisedData, setPrevisualisedData] = useState([]);

  const tableSettings = tablesMapping?.[`${mappingKey}`]?.table;
  const headers = tableSettings?.map((item) => item?.label);
  const dataToExport = dataSource?.map((item) => item?.data);

  const getImportFile = () => {
    document.querySelector(".UploadComponentWrapper input").click();
  };

  const getCorrectObject = (data) => {
    const corrected = [];
    for (let index = 0; index < data.length; index++) {
      const newData = data[index];
      const newDataKeys = Object.keys(newData);
      let correctedData = {};
      for (let subIndex = 0; subIndex < tableSettings.length; subIndex++) {
        const setting = tableSettings[subIndex];
        if (newDataKeys.indexOf(setting?.label) !== -1) {
          correctedData[setting?.key] = newData[setting?.label];
        }
      }
      corrected.push(correctedData);
    }
    return corrected;
  };

  const readImportedfile = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true, // Set to true if the first row of the CSV are headers
        skipEmptyLines: true,
        complete: (results) => {
          const transData = transformDataForTableComponent(
            tableSettings,
            getCorrectObject(results?.data)
          );
          setPrevisualisedData(transData);
          setparsedData(results.data);
        },
        error: (error) => {
          console.error("Error parsing:", error);
        },
      });
    }
  };

  const importNewDocuments = async () => {
    try {
      if (!!parsedData?.length) {
        const dataToSend = getCorrectObject(parsedData);
        const response = await postActions(
          endpointsMapping?.[`${mappingKey}`]["insert-many"],
          { data: dataToSend }
        );
        showToast(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const options = [
    { label: "Tableau visible", value: "current" },
    { label: "Tout Exporter", value: "all" },
  ];
  const _onSelect = async (value) => {
    if (value.value === "current") {
      downloadCsvFile(headers, dataToExport);
    } else {
      const response = await getAction(
        endpointsMapping?.[`${mappingKey}`]["get"],
        {
          skip: 0,
          limit: metaCount,
        }
      );
      const transData = transformDataForTableComponent(
        tableSettings,
        response?.data
      )?.map((item) => item?.data);
      downloadCsvFile(headers, transData);
    }
  };

  const tableIcon = (
    <svg className="app" viewBox="0 0 24 24">
      <path d="M6.5 20Q4.22 20 2.61 18.43 1 16.85 1 14.58 1 12.63 2.17 11.1 3.35 9.57 5.25 9.15 5.88 6.85 7.75 5.43 9.63 4 12 4 14.93 4 16.96 6.04 19 8.07 19 11 20.73 11.2 21.86 12.5 23 13.78 23 15.5 23 17.38 21.69 18.69 20.38 20 18.5 20H13Q12.18 20 11.59 19.41 11 18.83 11 18V12.85L9.4 14.4L8 13L12 9L16 13L14.6 14.4L13 12.85V18H18.5Q19.55 18 20.27 17.27 21 16.55 21 15.5 21 14.45 20.27 13.73 19.55 13 18.5 13H17V11Q17 8.93 15.54 7.46 14.08 6 12 6 9.93 6 8.46 7.46 7 8.93 7 11H6.5Q5.05 11 4.03 12.03 3 13.05 3 14.5 3 15.95 4.03 17 5.05 18 6.5 18H9V20M12 13Z" />
    </svg>
  );

  return (
    <div className="UploadComponentWrapper">
      {!!parsedData?.length ? (
        <>
          <div className="goBack">
            <div
              className="container"
              onClick={() => {
                setparsedData([]);
              }}>
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M18,11H10L13.5,7.5L12.08,6.08L6.16,12L12.08,17.92L13.5,16.5L10,13H18V11Z"
                />
              </svg>
            </div>
          </div>
          <h2>Prévisualisation des documents</h2>
          <TableComponent
            key={mappingKey}
            icon={tableIcon}
            settings={tableSettings}
            dataSource={previsualisedData}
            withMaxWidth={true}
            currentKey={mappingKey}
            displayOption={false}
          />
          <br />
          <div className="center">
            <button onClick={importNewDocuments} className="app-btn">
              Finaliser l'import
            </button>
          </div>
        </>
      ) : (
        <div className="options">
          <br />
          <div className="opt">
            <input
              style={{ display: "none" }}
              type="file"
              onChange={readImportedfile}
            />
            <Dropdown
              style={{
                marginRight: "20px",
              }}
              dropdownStyle={{
                right: "10px",
              }}
              options={options}
              onSelect={_onSelect}>
              {" "}
              <div className="app-btn">Exporter</div>
            </Dropdown>
            <button
              style={{ height: 50, marginLeft: -25 }}
              onClick={() => downloadCsvFile(headers, [])}
              className="app-btn">
              Modèle
            </button>
            {tablesMapping?.[`${mappingKey}`]?.params?.canImport && (
              <button
                style={{ height: 50 }}
                onClick={getImportFile}
                className="app-btn">
                Importer
              </button>
            )}
          </div>
          <h3>
            {getIconByKey("alert")}
            Attention à importer le modèle avec la première ligne telle quelle
            non modifiée
          </h3>
        </div>
      )}
    </div>
  );
};

export default UploadComponent;
