import { saveAs } from "file-saver";
// Helper function to escape fields with commas or quotes
const escapeField = (field) => {
  // Ensure field is treated as a string
  const stringField = String(field);

  // Check for commas or quotes in the field
  if (stringField.includes(",") || stringField.includes('"')) {
    // Escape double quotes and enclose the field in double quotes
    const returnedStringField = `"${stringField
      .replaceAll(/\n/g, " ")
      .replaceAll(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
      .replaceAll(/"/g, '""')}"`;

    return returnedStringField;
  }
  return stringField; // Return the field as is if no commas or quotes
};
export const downloadCsvFile = (headers, data) => {
  // Create a CSV string
  let csvContent = headers.map(escapeField).join(",") + "\r\n";
  data.forEach((row) => {
    csvContent += row.map(escapeField).join(",") + "\r\n";
  });

  // Convert CSV string to a Blob
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Use file-saver to save the CSV file
  saveAs(blob, "data.xlsx");
};
