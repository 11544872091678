import * as authTypes from '../constants/auth.types.js'

let initialState = {
    loading: false,
    authSuccessData: null,
    authErrorData: null,
    userInfos: null,
    reloadList:false
}

if (sessionStorage.getItem('auth')) {
    initialState = JSON.parse(sessionStorage.getItem('auth'));
}


export const authReducer = (state = initialState, action) => {

    let copyState = {...state} ;

    switch (action.type)
    {
        case authTypes.AUTH_REQUEST:
            copyState.loading = true;
            copyState.reloadList = false;
            break;

        case authTypes.AUTH_REQUEST_SUCCESS:
            copyState.loading = false;
            copyState.authSuccessData = action.payload
            break;

        case authTypes.AUTH_REQUEST_ERROR:
            copyState.loading = false;
            copyState.authErrorData = action.payload
            break; 

        // 
            
        case authTypes.USER_INFOS_REQUEST:
            copyState.loading = true;
            break;

        case authTypes.USER_INFOS_SUCCESS:
            copyState.loading = false;
            copyState.userInfos = action.payload
            break;

        case authTypes.USER_INFOS_ERROR:
            copyState.loading = false;
            copyState.userInfos = action.payload
            break;
        
        //
        
        case authTypes.LOGOUT:
            copyState.userInfos       = null;
            copyState.authSuccessData = null;
            copyState.authErrorData   = null;
            copyState.reloadList      = false;
            break; 

        // 
        case authTypes.RELOAD_LIST:
            copyState.reloadList = true;
            break;
            
        case authTypes.STOP_RELOAD_LIST:
            copyState.reloadList = false
            break;

        default:
            break;
    }
    sessionStorage.setItem('auth', JSON.stringify(copyState));
    return copyState;
}