
import './BreadCumb.scss'


const BreadCumb = ({situation}) => 
{
	return(
		<div className="BreadCumbWrapper">
			<div className='item'>
				<svg viewBox="0 0 24 24">
					<path fill="currentColor" d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69M12 3L2 12H5V20H11V14H13V20H19V12H22" />
				</svg>
			</div>
			{
				situation?.length > 0 && 
				situation?.map((item, index) =>(
					<div className='container' key={`${item?.key}-${index+100000}`}>
						<div  className='item'>
								/
						</div>
						<div className='item'>
								{item?.label}
						</div>
					</div>
					
				))
			}			
		</div>
	)
}

export default BreadCumb